import { lazy, Suspense } from 'react';
import { property } from 'underscore';
import { v as bvVar } from 'bv';
import { withErrorBoundary } from 'bv-error-boundary';
import ErrorBoundaryFallback from 'bv-components/error_boundary_fallback';
import { bootApp } from '../bv';
import retry from './retry';
import componentDependencies from './component_dependencies';

const modulesQMDelayedStart = ['login', 'registration'];

export default (fallbackComponent = null) => (componentKey) => {
  const LazyComponent = lazy(() => retry(() => {
    const {
      app,
      dependencies,
      softDependencies,
      subModule,
    } = property(componentKey.split('.'))(componentDependencies);

    if (modulesQMDelayedStart.includes(componentKey) && bvVar('shouldQMStartDelayed')) {
      window.startQM();
    }

    return bootApp({
      dependencies: dependencies || [],
      softDependencies,
      app,
      subModule,
    });
  }));

  const WithLazyLoad = (props) => (
    <Suspense fallback={fallbackComponent}>
      <LazyComponent {...props} />
    </Suspense>
  );

  WithLazyLoad.displayName = 'withLazyLoad';

  return withErrorBoundary(WithLazyLoad, {
    FallbackComponent: ErrorBoundaryFallback,
  });
};
