import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { showTopLevelNavigation } from 'global-config';
import { useSeoEnabled } from 'Seo/helpers';
import { withErrorBoundary } from 'bv-error-boundary';
import withLazyLoad from 'bv-lazy-load';
import TabsMenu from './tabs_menu';
import ErrorBoundaryFallback from './error_boundary_fallback';

const LazySeo = withLazyLoad()('seo');

const ContentLayout = ({ showTabs, children, fetching }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const seoEnabled = useSeoEnabled();

  const handleLoad = () => setIsLoaded(true);

  useEffect(() => {
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div className="react-contents">
      {showTabs && showTopLevelNavigation && <TabsMenu />}
      {children}
      {seoEnabled && !fetching && isLoaded && <LazySeo />}
    </div>
  );
};

ContentLayout.propTypes = {
  showTabs: PropTypes.bool,
  fetching: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ContentLayout.defaultProps = {
  showTabs: false,
  fetching: false,
};

export default withErrorBoundary(ContentLayout, {
  FallbackComponent: ErrorBoundaryFallback,
});
