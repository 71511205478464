import { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Modal from './modal';

const ErrorBoundaryFallback = ({ title }) => {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  const hide = () => { setVisible(false); };

  return (
    <Modal
      onCloseClick={hide}
      danger
      iconClosable
    >
      {title || t('errors_something_wrong')}
    </Modal>
  );
};

ErrorBoundaryFallback.propTypes = {
  title: PropTypes.string,
};

ErrorBoundaryFallback.defaultProps = {
  title: null,
};

export default ErrorBoundaryFallback;
