/* eslint react/no-multi-comp:off, max-classes-per-file: off, */
import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { requireScript } from 'bv';
import NativePlayer from './native';

const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
const HTTP_PROTOCOL = /http:/;

class HlsThirdPartyPlayer extends PureComponent {
  constructor(props) {
    super(props);

    const { opts } = props;
    this.videoRef = opts.ref ? opts.ref : createRef();
  }

  componentWillUnmount() {
    // Just in case the component is unmounted
    // before the third party library is completely loaded
    // and the hls object has been created
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
  }

  render() {
    const { url, opts } = this.props;

    requireScript(window.HLS_PLAYER_SCRIPT_PATH).then(() => {
      const { Hls } = window;

      this.hls = new Hls(opts.hlsConfig);

      this.hls.loadSource(url);
      this.hls.attachMedia(this.videoRef.current);
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hls.recoverMediaError();
              break;
            default:
              // cannot recover
              this.hls.destroy();
              break;
          }
        }
      });
    });

    return (
      <video ref={this.videoRef} {...opts} />
    );
  }
}

HlsThirdPartyPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
};

// We're using HlsPlayer instead of Hls for the name of the class
// to avoid collisions with the name exported by the library which is Hls
class HlsPlayer extends PureComponent {
  static typeName = 'HlsPlayer';

  static canPlay = (url) => HLS_EXTENSIONS.test(url);

  render() {
    const { url, opts } = this.props;

    // Real IOS devices cant run. We cannot check User-Agent since browsers can emulate this
    const Player = HTTP_PROTOCOL.test(url) || opts.ref?.current?.canPlayType('application/vnd.apple.mpegurl')
      ? NativePlayer
      : HlsThirdPartyPlayer;

    return (
      <Player
        url={url}
        opts={opts}
      />
    );
  }
}

HlsPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
};

export default HlsPlayer;
