import * as bodyScrollLock from 'body-scroll-lock';
import { compose, partial } from 'underscore';
import { isMediumOrGreater, isLargeOrGreater } from '../helpers/screen';

/* =============================

Reference sizes:
  0..1023    => small
  1024..1279 => medium
  1280..X    => big

Components using BodyScrollManager (should lock the scroll in certain conditions):
  Left navigation (id: left-components, className: left-subnavigation__content). small and medium
  Right sidebar (id: right-sidebar-scrollable). small only
  Modals (id: modal_contents_wrapper). small only

============================= */

const ignoreLockConditions = [
  {
    fn: isMediumOrGreater,
    selectors: {
      id: ['right-sidebar-scrollable'],
      className: ['right-sidebar-scrollable'],
    },
  },
  {
    fn: isLargeOrGreater,
    selectors: {
      id: ['left-components'],
      className: ['left-subnavigation__content'],
    },
  },
];

const validForScreen = (element) => (
  !ignoreLockConditions.some((condition) => {
    if (!condition.fn()) return undefined;

    const idSelectors = condition.selectors.id;
    const classNameSelectors = condition.selectors.className;

    return (
      idSelectors?.includes(element.id)
      || classNameSelectors?.some((className) => element.classList.contains(className))
    );
  })
);

const getElements = (selectorType, selector) => {
  if (selectorType === 'id') return [document.getElementById(selector)];
  if (selectorType === 'className') return Array.prototype.slice.call(document.getElementsByClassName(selector));
  return undefined;
};

const refresh = (action, elements) => {
  (Array.isArray(elements) ? elements : [elements])
    .filter(Boolean)
    .filter(validForScreen)
    .forEach((elem) => bodyScrollLock[`${action}BodyScroll`](elem, { reserveScrollBarGap: true }));
};

const disable = partial(refresh, 'disable');
const enable = partial(refresh, 'enable');
const getElementsByClassName = partial(getElements, 'className');
const getElementById = partial(getElements, 'id');
const disableById = compose(disable, getElementById);
const enableById = compose(enable, getElementById);
const disableByClassName = compose(disable, getElementsByClassName);
const enableByClassName = compose(enable, getElementsByClassName);

export {
  disable,
  enable,
  disableById,
  enableById,
  disableByClassName,
  enableByClassName,
};
