const SET_CAN_SUBMIT = 'captcha/SET_CAN_SUBMIT';

export const setCanSubmit = (canSubmit) => ({
  type: SET_CAN_SUBMIT,
  payload: { canSubmit },
});

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_CAN_SUBMIT:
      return {
        canSubmit: action.payload.canSubmit,
      };
    default:
      return state;
  }
};
