/* global hcaptcha */
import { v as bvVar } from 'bv';
import { setCanSubmit } from 'SharedComponents/hcaptcha/duck';
import { locale } from '../i18n';
import BaseHCaptchaService from './base_hcaptcha_service';

const { hcaptcha: hcaptchaSettings } = bvVar('captchaSettings');

export default class HCaptchaCaptchaService extends BaseHCaptchaService {
  isHCaptcha = () => true;

  token = '';

  refresh = () => {
    const { siteKey } = hcaptchaSettings;
    try {
      if (this.widgetId) {
        hcaptcha.reset(this.widgetId);
        this.token = '';
        this.setChallengeCompleted(false);
      } else {
        this.widgetId = hcaptcha.render('hcaptcha', {
          sitekey: siteKey,
          hl: locale().substr(0, 2),
          callback: (token) => {
            this.token = token;
            this.setChallengeCompleted(true);
          },
          'expired-callback': () => {
            this.token = '';
            this.setChallengeCompleted(false);
          },
        });
      }
    } catch {
      // noop
    }
  };

  execute = () => Promise.resolve(this.token);

  destroy = () => {
    try {
      this.setChallengeCompleted(false);
      this.widgetId = '';
      this.token = '';
      hcaptcha.reset(this.widgetId);
    } catch {
      // noop
    }
  };

  setChallengeCompleted = (completed) => {
    this.dispatch(setCanSubmit(completed));
  };
}
