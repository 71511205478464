import { t } from 'bv-i18n';
import { chunk, range } from 'underscore';
import withTouch from '../hocs/with_touch';

const Key = withTouch('betslip-keypad-active-key')('td');

const BetslipLayout = ({ addChar, removeChar, onEnterClick }) => {
  const rowsExtra = [
    [
      <Key onClick={removeChar} key="removeChar">
        <span className="betslip-keypad-back-icon" />
      </Key>,
    ],
    [
      <td
        className="betslip-keypad-enter"
        onClick={onEnterClick}
        rowSpan="3"
        key="enter"
      >
        <span className="betslip-keypad-enter-icon" />
      </td>,
    ],
  ];

  const rows = [
    ...chunk(range(1, 10), 3).reverse(),
    ['00', 0, t('javascript.currency.format.separator')],
  ]
    .map((row) => row.map((column) => (
      <Key onClick={(e) => addChar(e.target.textContent)} key={column}>
        {column}
      </Key>
    )),
    )
    .map((row, index) => row.concat(rowsExtra[index]));

  return rows.map((row) => <tr key={row.join('-')}>{row}</tr>);
};

export default BetslipLayout;
