import { t } from 'bv-i18n';
import { chunk, range } from 'underscore';
import withTouch from '../hocs/with_touch';

const Key = withTouch('cashier-keypad-active-key')('td');

const CashierLayout = ({ addChar, removeChar }) => {
  const separator = t('javascript.currency.format.separator');
  const rowExtra = [
    [
      <Key onClick={() => addChar(separator)} key={separator}>
        {separator}
      </Key>,
      <Key onClick={() => addChar('0')} key="0">
        0
      </Key>,
      <Key onClick={removeChar} key="removeChar">
        <span className="cashier-keypad-back-icon" />
      </Key>,
    ],
  ];

  const rows = [...chunk(range(1, 10), 3)]
    .map((row) => row.map((column) => (
      <Key onClick={(e) => addChar(e.target.textContent)} key={column}>
        {column}
      </Key>
    ))).concat(rowExtra);

  return rows.map((row) => <tr key={row.join('-')}>{row}</tr>);
};

export default CashierLayout;
