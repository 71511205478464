import { v as bvVar } from 'bv';
import { uniq } from 'underscore';
import DisabledCaptchaService from './disabled_captcha_service';
import CustomCaptchaService from './custom_captcha_service';
import GoogleCaptchaService from './google_captcha_service';
import HCaptchaCaptchaService from './hcaptcha_service';
import InvisibleHCaptchaService from './invisible_hcaptcha_service';

const captchaStrategies = bvVar('captchaStrategies');

const Services = {
  custom_solution: CustomCaptchaService,
  disabled: DisabledCaptchaService,
  google_recaptcha_v3: GoogleCaptchaService,
  hcaptcha: HCaptchaCaptchaService,
  invisible_hcaptcha: InvisibleHCaptchaService,
};

export const CaptchaActions = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  EMAIL_VERIFICATION: 'email_verification',
  EMAIL_VALIDATION: 'email_validation',
  USER_LOOKUP: 'user_lookup',
};

const captchaServices = {};

const getStrategyForAction = (action) => (
  captchaStrategies[action] || captchaStrategies.default
);

const getCaptchaServiceByStrategy = (strategy) => {
  if (!captchaServices[strategy]) {
    captchaServices[strategy] = new Services[strategy]();
  }
  return captchaServices[strategy];
};

const getStrategiesForActions = (actions) => (
  uniq(actions.map((action) => getStrategyForAction(action)))
);

export const getCaptchaService = (action) => (
  getCaptchaServiceByStrategy(getStrategyForAction(action))
);

export const loadCaptchaServices = (actions, opts) => Promise.all(
  getStrategiesForActions(actions).map(
    (strategy) => (
      getCaptchaServiceByStrategy(strategy).load(opts)
    ),
  ),
);

export const destroyCaptchaServices = (actions) => getStrategiesForActions(actions).map(
  (strategy) => (
    getCaptchaServiceByStrategy(strategy).destroy()
  ),
);
