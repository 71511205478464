import PropTypes from 'prop-types';
import { useEffect, useCallback, memo } from 'react';
import { isEqual, omit } from 'underscore';
import { t } from 'bv-i18n';
import BetslipLayout from './betslip_layout';
import CashierLayout from './cashier_layout';

const Keyboard = memo(
  ({
    layout, onChange, onEnterClick, value,
  }) => {
    // Add character handler
    const addChar = useCallback(
      (char) => {
        const newValue = `${value}${char}`;

        // Handle inputs starting with '0'
        if (/^0[^.|,]/.test(newValue)) {
          if (newValue.length === 2 && /^[0-9]$/.test(newValue[1])) {
            onChange(newValue[1]);
          }
          return;
        }

        const formattedValue = newValue
          .replace(/^([.|,])/, '0$1')
          .replace(/[.,]/, t('javascript.currency.format.separator'));

        // Match digits + decimal point + 0 to 2 digits
        if (/^\d*([.|,]\d{0,2})?$/.test(formattedValue)) {
          onChange(formattedValue);
        }
      },
      [onChange, value],
    );

    // Remove character handler
    const removeChar = useCallback(() => {
      onChange(value.slice(0, -1));
    }, [onChange, value]);

    // Key down handler
    const onKeyDown = useCallback(
      (event) => {
        switch (event.key) {
          case 'Enter':
            onEnterClick();
            break;
          case 'Backspace':
            removeChar();
            break;
          default:
            addChar(event.key);
        }
      },
      [addChar, onEnterClick, removeChar],
    );

    useEffect(() => {
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [onKeyDown]);

    const Layout = layout === 'betslip' ? BetslipLayout : CashierLayout;

    return (
      <div className={`${layout}-keypad-keys-wrapper`}>
        <table className={`${layout}-keypad-keys`}>
          <tbody>
            <Layout addChar={addChar} removeChar={removeChar} onEnterClick={onEnterClick} />
          </tbody>
        </table>
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(omit(prevProps, 'value'), omit(nextProps, 'value')),
);

Keyboard.propTypes = {
  layout: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnterClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Keyboard;
