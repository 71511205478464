import {
  useEffect, useReducer, useState, useMemo,
} from 'react';
import { reducer, setCanSubmit } from 'SharedComponents/hcaptcha/duck';
import {
  getCaptchaService, loadCaptchaServices, destroyCaptchaServices, CaptchaActions,
} from 'bv-services/captcha';

const useCaptchaLoader = (action) => {
  const actions = useMemo(() => {
    switch (action) {
      case CaptchaActions.LOGIN:
        return [CaptchaActions.LOGIN, CaptchaActions.USER_LOOKUP];
      case CaptchaActions.REGISTRATION:
        return [
          CaptchaActions.REGISTRATION,
          CaptchaActions.EMAIL_VERIFICATION,
          CaptchaActions.EMAIL_VALIDATION,
        ];
      default:
        return [action];
    }
  }, [action]);

  const [loading, setLoading] = useState(true);
  const [context, dispatch] = useReducer(reducer, { canSubmit: false });

  useEffect(() => {
    loadCaptchaServices(actions, { dispatch }).then(() => {
      setLoading(false);
      const captchaService = getCaptchaService(action);
      dispatch(setCanSubmit(!captchaService.isHCaptcha()));
    });

    return () => destroyCaptchaServices(actions);
  }, [action]);

  return [context, loading];
};

export default useCaptchaLoader;
