export default class BaseCaptchaService {
  isDisabled = () => false;

  isHCaptcha = () => false;

  isInvisibleHCaptcha = () => false;

  isHcaptchaType = () => this.isHCaptcha() || this.isInvisibleHCaptcha();

  load = () => Promise.resolve();

  // returns the captcha token: Promise<String>
  execute = () => undefined;

  shouldRetry = () => false;

  refresh = () => Promise.resolve();

  destroy = () => undefined;
}
