import { v as bvVar, requireScript } from 'bv';
import BaseCaptchaService from './base_captcha_service';

const { hcaptcha: hcaptchaSettings } = bvVar('captchaSettings');

export default class BaseHCaptchaService extends BaseCaptchaService {
  dispatch = null;

  widgetId = '';

  load = async ({ dispatch }) => {
    const { jsUrl } = hcaptchaSettings;
    this.dispatch = dispatch;
    return requireScript(`${jsUrl}?render=explicit`);
  };
}
