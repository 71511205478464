import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isInComponentsIframe } from 'bv-services/embedded';

const Overlay = ({ fullscreen, onClick }) => {
  const className = classnames('bvs-overlay', {
    'bvs-overlay--fullscreen': fullscreen || isInComponentsIframe(),
  });

  return <div className={className} onClick={onClick} />;
};

Overlay.propTypes = {
  onClick: PropTypes.func,
  fullscreen: PropTypes.bool,
};

Overlay.defaultProps = {
  onClick: () => {},
  fullscreen: false,
};

export default Overlay;
